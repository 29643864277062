
import React from "react";
import { PieChart, Pie } from "recharts";

const data01 = [
  { name: "Group A", value: 500 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 100 }
];
const data02 = [
  { name: "A1", value: 100 },
  { name: "A2", value: 300 },
  { name: "B1", value: 100 },
  { name: "B2", value: 80 },
  { name: "B3", value: 40 },
  { name: "B4", value: 30 },
  { name: "B5", value: 50 },
  { name: "C1", value: 100 },
  { name: "C2", value: 200 },
  { name: "D1", value: 150 },
  { name: "D2", value: 50 }
];

export default function PieChart1({color1= "#82ca9d", color2="#8884d8"}) {
  return (
    <PieChart width={350} height={350} style={{marginLeft:"-50px"}}>
      <Pie
      className="fs-14"
        data={data01}
        dataKey="value"
        cx={200}
        cy={200}
        outerRadius={60}
        fill={color1}
      />
      <Pie
      className="fs-14"
        data={data02}
        dataKey="value"
        cx={200}
        cy={200}
        innerRadius={70}
        outerRadius={90}
        fill={color2}
        label
      />
    </PieChart>
  );
}

import { useState, createContext, useEffect } from "react"
import { supabase } from "../supabase"

export const userContext = createContext()

export default function ContextPrivider({ children }) {

    const [isLogin, setIsLogin] = useState(false)
    const [userInfos, setUserInfos] = useState({})
    const [bitInfos, setBitInfos] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const getUserInfo = async () => {
        setIsLoading(true)
        const token = localStorage.getItem("token")
        if (token) {
            let { data, error } = await supabase.from("users").select("*").eq("id", token)
            if (error === null) {
                setIsLogin(true)
                setUserInfos(data[0])
            }
        }
        setIsLoading(false)
    }

    const logoutUser = () => {
        setIsLogin(false)
        setUserInfos({})
        localStorage.removeItem("token")
    }


    const getAmount = async () => {
        // setIsLoading(true)
        const allData = await fetch("https://api.coinbase.com/v2/prices/BTC-USD/spot")
        const data = await allData.json()
        setBitInfos(data.data)
        // setIsLoading(false)
    }

    useEffect(() => {
        getUserInfo()
        getAmount()
    }, [])

    return (
        <userContext.Provider value={{
            isLogin, setIsLogin,
            userInfos, setUserInfos ,
            isLoading, setIsLoading,
            logoutUser,
            bitInfos,
        }}>
            {children}
        </userContext.Provider>
    )
}
import "./styles/reset.css"
import "./styles/bootstrap.min.css"
import "./styles/default.css"
import './App.css';
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import Footer from "./Components/Footer/Footer";
import { useEffect, useRef, useState } from "react";
import Notification from "./Components/Notification/Notification";
import Navbar from "./Components/Header/Navbar";
import ContextPrivider from "./Contexts/userContext";


function App() {

  const [scroll, setScroll] = useState(false)

  const router = useRoutes(routes)

  const fixedHeader = useRef()

  useEffect(() => {
    fixedHeader.current.addEventListener("scroll", e => {
      if (e.target.scrollTop) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    })
  })

  return (
    <>
      <ContextPrivider>
        <div className=" d-flex bg-primary">
          <div className="bg-primary p-5 text-white w-50 display-mobil-none" style={{ zIndex: 9 }}>
            <h1>Blockchain App & Crypto Wallet Mobile Template</h1>
            <p className="text-white">Blockchain App & Crypto Wallet Mobile Template The first specilalized crypto app
              in the market and most powerful crypto wallet that fits perfectly any crypto mobile
              project with more and more features.
            </p>
          </div>
          <div className="App-container">
            <div className="main-div" ref={fixedHeader}>
              <div className={scroll ? `position-fixed flex-ac bg-white text-dark z-3 mb-3 p-2 header-scroll` : `position-fixed flex-ac bg-primary z-3 mb-3 p-2 header-scroll`}>
                <Notification scroll={scroll} />
                <Navbar scroll={scroll} />
              </div>
              {router}
            </div>
            <div className="bg-white">
              <Footer />
            </div>
          </div>
        </div>
      </ContextPrivider>
    </>
  );
}

export default App;

export const validator = (value , arrey) => {

    let falseArrey = []

    arrey.forEach(validation => {
        if(validation.name === "req"){
            value.length === 0 && falseArrey.push(false)
        } 
        else if (validation.name === "min"){
            value.length < validation.min && falseArrey.push(false)
        }
        else if (validation.name === "max"){
            value.length > validation.max && falseArrey.push(false)
        }
        else if (validation.name === "email"){
             !value.includes("@") && falseArrey.push(false)
        }
    });


    if(falseArrey.length > 0){
        return false
    } else {
        return true
    }
}
import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

const data = [
  {
    name: "A",
    uv: 400,
    pv: 240,
    amt: 240
  },
  {
    name: "B",
    uv: 300,
    pv: 139,
    amt: 221
  },
  {
    name: "C",
    uv: 200,
    pv: 980,
    amt: 229
  },
  {
    name: "D",
    uv: 278,
    pv: 390,
    amt: 200
  },
  {
    name: "E",
    uv: 189,
    pv: 480,
    amt: 218
  },
  {
    name: "F",
    uv: 239,
    pv: 380,
    amt: 250
  },
  {
    name: "G",
    uv: 349,
    pv: 430,
    amt: 210
  },
  {
    name: "H",
    uv: 200,
    pv: 980,
    amt: 229
  },
  {
    name: "I",
    uv: 278,
    pv: 390,
    amt: 200
  },
  {
    name: "J",
    uv: 189,
    pv: 480,
    amt: 218
  },
  {
    name: "K",
    uv: 239,
    pv: 380,
    amt: 250
  },
  {
    name: "L",
    uv: 149,
    pv: 230,
    amt: 210
  }
];

export default function AreaChart1({color1= "#82ca9d", color2="#8884d8"}) {
  return (
    <AreaChart
      width={320}
      height={300}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey="uv" stroke={color1} fill={color2} />
    </AreaChart>
  );
}






import React from 'react'
import Notification from '../../Components/Notification/Notification'
import Navbar from '../../Components/Header/Navbar'
import TransactionBox from '../../Components/Transactions/TransactionBox'
import AreaChart from '../../Components/Charts/AreaChart'
import TradeBox from '../../Components/TradeBox/TradeBox'
import SocialIcons from '../../Components/SocialIcons/SocialIcons'
import { Box, Tab, Tabs } from '@mui/material'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
export default function Trade() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <div className='py-5'>
            <div className="bg-primary p-3">
                <div className='flex-ac px-2 pb-5'>
                    <h6 className='text-white m-0 fw-bold'>Crypto Trade</h6>
                    <i className='bx bx-plus-medical text-white ms-auto fs-5 pointer'></i>
                </div>
            </div>

            <div style={{ marginTop: "-40px" }} className='bg-white mx-2 mb-5'>
                <TradeBox name="Bitcoin" rate="+0.94853" success={true} count="$2,748.94" img="bit.png" />
                <AreaChart bg="#fdcc64" color="#fed176" text={"Bitcoin Chart"} />
            </div>
            <div className='bg-white mx-2 mb-5'>
                <TradeBox name="Ethereum" rate="-1.4253" success={false} count="$1,241.10" img="eth.png" />
                <AreaChart bg="#0096c7" color="#00b4d8" text={"Ethereum Chart"} />
            </div>
            <div className='d-flex mb-4'>
                <div className='p-3 m-2 w-50 shadow rounded-3 bg-blue '>
                    <img className='mb-2' src='img/t1.png' width={40} />
                    <p className='mb-2 text-white'>Buy crypto</p>
                    <p className='fs-14 flex-ac text-white'>Lorem ipsum, dolor sit amet</p>
                </div>
                <div className='p-3 m-2 w-50 shadow rounded-3 bg-blue '>
                    <img className='mb-2' src='img/t1.png' width={40} />
                    <p className='mb-2 text-white'>Sell crypto</p>
                    <p className='fs-14 flex-ac text-white'>Lorem ipsum, dolor sit amet</p>
                </div>
            </div>
            <div className='my-4 p-2'>
                <h6 className='fw-bold'>Buy & Sell Orders</h6>
                <Box sx={{ border: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="Buy & Sell Orders" centered>
                        <Tab label="All" {...a11yProps(0)} className='fw-bold' />
                        <Tab label="Buy" {...a11yProps(1)} className='fw-bold' />
                        <Tab label="Sell" {...a11yProps(2)} className='fw-bold' />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <div>
                        <TradeBox name="Ethereum" rate="-1.4253" success={false} count="$1,241.10" img="eth.png" />
                        <TransactionBox name="Bitcoin" date="08-24 20.04PM" rate="+0.94853" success={true} count="$2,748.94" img="bit.png" />
                        <TradeBox name="Dashcoin" rate="+0.2536" success={true} count="$3,721.48" img="dash.png" />
                        <TransactionBox name="Ripple" date="08-19 14.10PM" rate="-0.7423" success={false} count="$0,989.85" img="rip.png" />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div>
                        <TradeBox name="Ripple" rate="-0.7423" success={false} count="$0,989.85" img="rip.png" />
                        <TradeBox name="Dashcoin" rate="+0.2536" success={true} count="$3,721.48" img="dash.png" />
                        <TradeBox name="Ethereum" rate="-1.4253" success={false} count="$1,241.10" img="eth.png" />
                        <TradeBox name="Bitcoin" rate="+0.94853" success={true} count="$2,748.94" img="bit.png" />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                <div>
                        <TransactionBox name="Ethereum" date="08-21 17.20PM" rate="+1.4253" success={true} count="$1,241.10" img="eth.png" />
                        <TransactionBox name="Bitcoin" date="08-24 20.04PM" rate="+0.94853" success={true} count="$2,748.94" img="bit.png" />
                        <TransactionBox name="Ripple" date="08-19 14.10PM" rate="-0.7423" success={false} count="$0,989.85" img="rip.png" />
                        <TransactionBox name="Dashcoin" date="08-20 20.25PM" rate="+0.2536" success={true} count="$3,721.48" img="dash.png" />
                    </div>
                </TabPanel>
            </div>
            <SocialIcons />
            <div className="flex-center my-5 p-3 fs-14">Copyright © All Right Reserved</div>
        </div>
    )
}

import React from 'react'
import Notification from '../../Components/Notification/Notification'
import Navbar from '../../Components/Header/Navbar'
import RadioBtn from '../../Components/RadioBtn/RadioBtn'
import SettingItem from '../../Components/SettingItem/SettingItem'


export default function Cards() {
    return (
        <div className='py-5'>
            <div className="bg-primary p-3">
            <div className='flex-ac px-2 pb-3'>
                    <h6 className='text-white m-0 fw-bold'>Card Setting</h6>
                    <i className='bx bx-plus-medical text-white ms-auto fs-5 pointer'></i>
                </div>
            </div>
            <h6 className='mt-4 mb-2 fw-bold ps-2'>Card Settings</h6>
            <div className='bg-primary m-2 p-3 rounded-3'>
                <div className='flex-ac mb-3'>
                    <i className='bx bx-check fs-5 p-2 text-white bg-success rounded-circle'></i>
                    <p className='ms-auto fs-3 fst-italic text-white m-0'>VISA</p>
                </div>
                <h5 className='text-white mb-3'>
                    <span className='me-2'>****</span>
                    <span className='me-2'>****</span>
                    <span className='me-2'>****</span>
                    <span>7541</span>
                </h5>
                <div className='flex-ac mb-2'>
                    <span className='fs-14 me-5'>Card Holder</span>
                    <span className='fs-14 me-5'>Expires</span>
                    <span className='fs-14 '>CVV</span>
                </div>
                <div className='flex-ac text-white'>
                    <span className='fs-14 me-4'>Masoud Bahamin</span>
                    <span className='fs-14 me-5'>08/26</span>
                    <span className='fs-14 '>342</span>
                </div>
            </div>
            <h6 className='fw-bold my-3 ps-2'>Edit Setting</h6>
            <div className='m-2'>
                <SettingItem value={true} name="Bitcoin" title="BTC" img="bit.png" />
                <SettingItem value={false} name="Bitcoincash" title="BCH" img="cash.png" />
                <SettingItem value={true} name="Ethereum" title="ETH" img="eth.png" />
                <SettingItem value={true} name="Dashcoin" title="DASH" img="dash.png" />
                <SettingItem value={false} name="Monero" title="XMR" img="mon.png" />
                <SettingItem value={false} name="Dogecoin" title="DOGE" img="dog.png" />
                <SettingItem value={true} name="Ripple" title="XRP" img="rip.png" />
            </div>
            <div className='p-2 mb-5 mt-3'>
                <button className='btn btn-success w-100'>Add New Asset</button>
            </div>

        </div>
    )
}

import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const data = [
  {
    name: "A",
    uv: 400,
    pv: 240,
    amt: 240
  },
  {
    name: "B",
    uv: 300,
    pv: 139,
    amt: 221
  },
  {
    name: "C",
    uv: 200,
    pv: 680,
    amt: 229
  },
  {
    name: "D",
    uv: 278,
    pv: 390,
    amt: 200
  },
  {
    name: "E",
    uv: 189,
    pv: 480,
    amt: 218
  },
  {
    name: "F",
    uv: 239,
    pv: 380,
    amt: 250
  },
  {
    name: "G",
    uv: 349,
    pv: 430,
    amt: 210
  },
  {
    name: "H",
    uv: 278,
    pv: 390,
    amt: 200
  },
  {
    name: "J",
    uv: 189,
    pv: 480,
    amt: 218
  },
];

export function BarChart1({color1= "#82ca9d", color2="#8884d8"}) {
  return (
    <BarChart
      width={310}
      height={300}
      data={data}
      margin={{
        top: 10,
        right: 5,
        left: 1,
        bottom: 10
      }}
    >
      <CartesianGrid strokeDasharray="3 8" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="pv" fill={color1} />
      <Bar dataKey="uv" fill={color2} />
    </BarChart>
  );
}

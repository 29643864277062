
import Affiliate from "./Pages/Affiliate/Affiliate";
import BuySell from "./Pages/Buy&Sell/Buy&sell";
import Cards from "./Pages/Cards/Cards";
import Charts from "./Pages/Charts/Charts";
import Contact from "./Pages/Contact/Contact";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Profile from "./Pages/Profile/Profile";
import Register from "./Pages/Register/Register";
import Trade from "./Pages/Trade/Trade";
import Wallet from "./Pages/Wallet/Wallet";
import Wizard from "./Pages/Wizard/Wizard";

const routes = [
    { path: "/", element: <Home /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/wallet", element: <Wallet /> },
    { path: "/profile", element: <Profile /> },
    { path: "/cards", element: <Cards /> },
    { path: "/trade", element: <Trade /> },
    { path: "/buy-sell", element: <BuySell /> },
    { path: "/affiliate", element: <Affiliate /> },
    { path: "/wizard", element: <Wizard /> },
    { path: "/charts", element: <Charts /> },
    { path: "/contact", element: <Contact /> },

]

export default routes
import React from 'react'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

export default function SocialIcons() {
  return (
    <div className='flex-center mb-3'>
        <i className='fs-3 rounded-circle bg-light p-2 pointer text-dark hover-icon bx bxl-facebook'></i>
        <i className='fs-3 rounded-circle bg-light p-2 pointer text-dark hover-icon bx bxl-twitter'></i>
        <i className='fs-3 rounded-circle bg-light p-2 pointer text-dark hover-icon bx bxl-google'></i>
        <i className='fs-3 rounded-circle bg-light p-2 pointer text-dark hover-icon bx bxl-instagram-alt'></i>
        <i className='fs-3 rounded-circle bg-light p-2 pointer text-dark hover-icon bx bxl-telegram'></i>
    </div>
  )
}

import React , {useContext} from 'react'
import Header from '../../Components/Header/Header'
import Notification from '../../Components/Notification/Notification'
import Transactions from '../../Components/Transactions/Transactions'
import { BarChart1 } from '../../Components/Charts/BarChart1'
import MultiTypeChart from '../../Components/Charts/MultiTypeChart'
import Theme from '../../Components/Theme/Theme'
import SocialIcons from '../../Components/SocialIcons/SocialIcons'
import OutboxIcon from '@mui/icons-material/Outbox';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import { Link } from 'react-router-dom';
import { userContext } from '../../Contexts/userContext'

export default function Home() {

  const {isLoading , userInfos} = useContext(userContext)

  return (
    <>
    {isLoading ? (
      <div className='position-absolute top-50 start-50'><div className='spinner-border'></div></div>
    ) : (
    <div className='py-5'>
      <div className="bg-primary p-3">
        <div className='d-flex'>
          <div>
            <h4 className='text-white h4'>$2,589.50</h4>
            <p className='text-white fs-12'>Total Balance</p>
          </div>
          <Link to="/profile" className='ms-auto'><img src={userInfos.img ? `/img/${userInfos.img}` : '/img/u1.png'} width={40} height={40} className="border rounded pointer" /></Link>
        </div>
        <div className='flex-ac justify-content-around'>
          <div>
            <OutboxIcon sx={{ width: 50, height: 50 }} className="rounded-3 p-2 text-primary bg-white mb-2 pointer" />
            <p className='text-white text-center fs-14'>Send</p>
          </div>
          <div>
            <SystemUpdateAltIcon sx={{ width: 50, height: 50 }} className="rounded-3 p-2 text-primary bg-white mb-2 pointer" />
            <p className='text-white text-center fs-14'>Recieve</p>
          </div>
          <div>
            <CreditScoreIcon sx={{ width: 50, height: 50 }} className="rounded-3 p-2 text-primary bg-white mb-2 pointer" />
            <p className='text-white text-center fs-14'>Loan</p>
          </div>
          <div>
            <CardTravelIcon sx={{ width: 50, height: 50 }} className="rounded-3 p-2 text-primary bg-white mb-2 pointer" />
            <p className='text-white text-center fs-14'>Topup</p>
          </div>
        </div>
      </div>
      <Transactions />
      <h6 className='px-3 fw-bold mb-3'>Balance Statistics</h6>
      <div className='p-1 mb-4'>
        <MultiTypeChart />
        <BarChart1 color1='yellow' />
      </div>

      <h6 className='my-3 px-3 fw-bold'>Cryptocoins Balance</h6>
      <div className='d-flex mb-5'>
        <div className='p-3 m-1 w-50 shadow rounded-3'>
          <img className='mb-2' src='img/t1.png' width={40} />
          <p>Total Income</p>
          <div style={{ width: "90%", height: 7, backgroundColor: "#e9ecef" }} className='rounded-3 my-3'>
            <div style={{ width: "50%", height: 7 }} className='bg-primary rounded-3'></div>
          </div>
          <p>$2,748,98</p>
        </div>
        <div className='p-3 m-1 w-50 shadow rounded-3'>
          <img className='mb-2' src='img/t2.png' width={40} />
          <p>Expenses</p>
          <div style={{ width: "90%", height: 7, backgroundColor: "#e9ecef" }} className=' rounded-3 my-3'>
            <div style={{ width: "70%", height: 7, backgroundColor: "#ff7e5e" }} className=' rounded-3'></div>
          </div>
          <p>$1,245,43</p>
        </div>
      </div>
      <SocialIcons />
      <div className="flex-center my-4 p-3 fs-14">Copyright © All Right Reserved</div>
    </div>)}
    </>
  )
}

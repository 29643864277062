import React, { useState } from 'react'
import "./Modal.css"

export default function Modal({ show, setShow , children}) {
    return (
        <>{!show ? <></> : (
            <div className='modal-container' onClick={e => {
                e.stopPropagation()
                setShow(false)
            }}>
                <div className='modal-main' onClick={e => e.stopPropagation()}>
                       {children}
                </div>
            </div>)}
        </>
    )
}

import React from 'react'
import Notification from '../../Components/Notification/Notification'
import Navbar from '../../Components/Header/Navbar'
import OutboxIcon from '@mui/icons-material/Outbox';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import UserSlider from '../../Components/Sliders/UserSlider';
import Transactions from '../../Components/Transactions/Transactions';
import SocialIcons from '../../Components/SocialIcons/SocialIcons';
import { userContext } from '../../Contexts/userContext';

export default function BuySell() {

    const [usdbit, setUsdbit] = React.useState(true)
    const [usdInput, setUsdInput] = React.useState(1)
    const [bitInput, setBitInput] = React.useState(1)

    const { bitInfos } = React.useContext(userContext)

    return (
        <div className='py-5 position-relative'>
            <div className="bg-primary p-3">
                <div className='flex-ac px-2 pb-5'>
                    <h6 className='text-white m-0 fw-bold'>Crypto Trade</h6>
                    <i className='bx bx-plus-medical text-white ms-auto fs-5 pointer'></i>
                </div>
            </div>

            <div style={{ marginTop: "-40px" }} className='bg-white mx-2 mb-5 border'>
                <div className='d-flex p-2 border-bottom'>
                    <p>Total Balance</p>
                    <p className='fw-bold ms-auto'>$8,356.45</p>
                </div>
                <div className='flex-ac justify-content-around'>
                    <div>
                        <OutboxIcon sx={{ width: 70, height: 70, color: "#48cae4" }} className="rounded-3 p-2 bg-white mb-1 pointer" />
                        <p className='text-center fs-14'>Send</p>
                    </div>
                    <div>
                        <SystemUpdateAltIcon sx={{ width: 70, height: 70, color: "#48cae4" }} className="rounded-3 p-2 bg-white mb-1 pointer" />
                        <p className='text-center fs-14'>Recieve</p>
                    </div>
                    <div>
                        <CreditScoreIcon sx={{ width: 70, height: 70, color: "#48cae4" }} className="rounded-3 p-2 bg-white mb-1 pointer" />
                        <p className='text-center fs-14'>Exchange</p>
                    </div>
                </div>
            </div>
            <h6 className='fw-bold my-4 ps-2'>Send Cryptocoin to</h6>
            <div className='my-2'>
                <UserSlider />
            </div>
            <h6 className='fw-bold mt-5 mb-3 ps-2'>Place Buy Order</h6>
            <div className='px-2 py-4 mb-4 bg-primary'>
                <h6 className='fw-bold text-white text-center my-4'>Buy CriptoCurrency</h6>
                <div className='px-2 my-3'>
                    <p className='m-0 text-white mb-1'>Select Cryptocurrency</p>
                    <div className='flex-ac bg-white rounded-2 mb-3 border-bottom p-1'>
                        <select className='form-select w-100 border-0 ' >
                            <option>Bitcoin</option>
                            <option>Etherium</option>
                            <option>Dashcoin</option>
                        </select>
                    </div>
                    <p className='m-0 text-white mb-1'>Select Payment Method</p>
                    <div className='flex-ac bg-white rounded-2 mb-3 border-bottom p-1'>
                        <select className='form-select w-100 border-0 ' >
                            <option>Master Card</option>
                            <option>Visa Card</option>
                        </select>
                    </div>
                    <p className='m-0 text-white mb-1'>Wallet Address</p>
                    <div className='flex-ac bg-white rounded-2 mb-3 border-bottom p-1'>
                        <i className='bx bx-briefcase' ></i>
                        <input type='text' className='form-control fs-14 w-75 border-0 '
                            placeholder="LKMN-ADSF-35VFJHNBG478SD" />
                    </div>
                    {usdbit ? (
                        <div className='flex-ac'>
                            <div className=' w-50'>
                                <p className='m-0 text-white mb-1'>USD Amount</p>
                                <div className='flex-ac bg-white rounded-2 me-2 mb-3 border-bottom p-1'>
                                    <i className='bx bx-briefcase' ></i>
                                    <input type='text' className='form-control fs-14 w-75 border-0 '
                                        placeholder={"USD"} value={usdInput} onChange={e => setUsdInput(e.target.value)}
                                    />
                                </div>
                            </div>
                            <i className='bx bx-transfer text-white' onClick={() => setUsdbit(prev => !prev)}></i>
                            <div className=' w-50'>
                                <p className='m-0 text-white mb-1 ps-2'>BTC Amount</p>
                                <div className='flex-ac bg-white rounded-2 ms-2 mb-3 border-bottom p-1'>
                                    <i className='bx bx-briefcase'></i>
                                    <div type='text' className='form-control fs-14 w-75 border-0 ' >
                                        {(usdInput * (1 / bitInfos.amount)).toFixed(6)}</div>
                                </div>
                            </div>
                        </div>) : (
                        <div className='flex-ac'>
                            <div className=' w-50'>
                                <p className='m-0 text-white mb-1 '>BTC Amount</p>
                                <div className='flex-ac bg-white rounded-2 me-2 mb-3 border-bottom p-1'>
                                    <i className='bx bx-briefcase' ></i>
                                    <input type='text' className='form-control fs-14 w-75 border-0 '
                                        placeholder={"BTC"} value={bitInput} onChange={e => setBitInput(e.target.value)} />
                                </div>
                            </div>
                            <i className='bx bx-transfer text-white' onClick={() => setUsdbit(prev => !prev)}></i>
                            <div className=' w-50'>
                                <p className='m-0 text-white mb-1 ps-2'>USD Amount</p>
                                <div className='flex-ac bg-white rounded-2 ms-2 mb-3 border-bottom p-1'>
                                    <i className='bx bx-briefcase' ></i>
                                    <div type='text' className='form-control fs-14 w-75 border-0 ' >
                                        {(bitInput * (bitInfos.amount)).toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <button className="btn btn-warning w-100 text-white" >Place Buy Order</button>
            </div>

            <h6 className='fw-bold mt-5 mb-3 ps-2'>Place Sell Order</h6>
            <div className='px-2 py-4 mb-4 bg-primary'>
                <h6 className='fw-bold text-white text-center my-4'>Sell CriptoCurrency</h6>
                <div className='px-2 my-3'>
                    <p className='m-0 text-white mb-1'>Select Cryptocurrency</p>
                    <div className='flex-ac bg-white rounded-2 mb-3 border-bottom p-1'>
                        <select className='form-select w-100 border-0 ' >
                            <option>Bitcoin</option>
                            <option>Etherium</option>
                            <option>Dashcoin</option>
                        </select>
                    </div>
                    <p className='m-0 text-white mb-1'>Wallet Address</p>
                    <div className='flex-ac bg-white rounded-2 mb-3 border-bottom p-1'>
                        <i className='bx bx-briefcase' ></i>
                        <input type='text' className='form-control fs-14 w-75 border-0 '
                            placeholder="LKMN-ADSF-35VFJHNBG478SD" />
                    </div>
                    <p className='m-0 text-white mb-1'>Confirm Password</p>
                    <div className='flex-ac bg-white rounded-2 mb-3 border-bottom p-1'>
                        <i className='bx bx-lock-alt' ></i>
                        <input type='text' className='form-control fs-14 w-75 border-0 '
                            placeholder="***********" />
                    </div>
                    {usdbit ? (
                        <div className='flex-ac'>
                            <div className=' w-50'>
                                <p className='m-0 text-white mb-1'>USD Amount</p>
                                <div className='flex-ac bg-white rounded-2 me-2 mb-3 border-bottom p-1'>
                                    <i className='bx bx-briefcase' ></i>
                                    <input type='text' className='form-control fs-14 w-75 border-0 '
                                        placeholder={"USD"} value={usdInput} onChange={e => setUsdInput(e.target.value)}
                                    />
                                </div>
                            </div>
                            <i className='bx bx-transfer text-white' onClick={() => setUsdbit(prev => !prev)}></i>
                            <div className=' w-50'>
                                <p className='m-0 text-white mb-1 ps-2'>BTC Amount</p>
                                <div className='flex-ac bg-white rounded-2 ms-2 mb-3 border-bottom p-1'>
                                    <i className='bx bx-briefcase'></i>
                                    <div type='text' className='form-control fs-14 w-75 border-0 ' >
                                        {(usdInput * (1 / bitInfos.amount)).toFixed(6)}</div>
                                </div>
                            </div>
                        </div>) : (
                        <div className='flex-ac'>
                            <div className=' w-50'>
                                <p className='m-0 text-white mb-1 '>BTC Amount</p>
                                <div className='flex-ac bg-white rounded-2 me-2 mb-3 border-bottom p-1'>
                                    <i className='bx bx-briefcase' ></i>
                                    <input type='text' className='form-control fs-14 w-75 border-0 '
                                        placeholder={"BTC"} value={bitInput} onChange={e => setBitInput(e.target.value)} />
                                </div>
                            </div>
                            <i className='bx bx-transfer text-white' onClick={() => setUsdbit(prev => !prev)}></i>
                            <div className=' w-50'>
                                <p className='m-0 text-white mb-1 ps-2'>USD Amount</p>
                                <div className='flex-ac bg-white rounded-2 ms-2 mb-3 border-bottom p-1'>
                                    <i className='bx bx-briefcase' ></i>
                                    <div type='text' className='form-control fs-14 w-75 border-0 ' >
                                        {(bitInput * (bitInfos.amount)).toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <button className="btn btn-warning w-100 text-white" >Place Sell Order</button>
            </div>
            <h6 className='fw-bold mt-5 mb-3 ps-2'>Easy Cripto Trading</h6>
            <div className='d-flex mb-4'>
                <div className='p-3 m-2 w-50 shadow rounded-3 bg-blue '>
                    <img className='mb-2' src='img/t1.png' width={40} />
                    <p className='mb-2 text-white'>Buy crypto</p>
                    <p className='fs-14 flex-ac text-white'>Lorem ipsum, dolor sit amet</p>
                </div>
                <div className='p-3 m-2 w-50 shadow rounded-3 bg-blue '>
                    <img className='mb-2' src='img/t1.png' width={40} />
                    <p className='mb-2 text-white'>Sell crypto</p>
                    <p className='fs-14 flex-ac text-white'>Lorem ipsum, dolor sit amet</p>
                </div>
            </div>
            <Transactions />
            <SocialIcons />
            <div className="flex-center my-5 p-3 fs-14">Copyright © All Right Reserved</div>
        </div>
    )
}

export const reqValid = () => {
    return{
        name:"req"
    }
}

export const minValid = (min) => {
    return {
        name:"min",
        min
    }
}

export const maxValid = (max) => {
    return {
        name : "max",
        max
    }
}
export const emailValid = () => {
    return {
        name : "email",
    }
}
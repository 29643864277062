import { Badge } from '@mui/material'
import React, { useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications';
import Modal from '../Modals/Modal';


export default function Notification({ scroll }) {
    const [notifications, setNotifications] = React.useState([
        { id: 1, text: "nofication 1 is in the table" },
        { id: 2, text: "you have a email from Webrund" },
        { id: 3, text: "nofication 3 is in the table" },
        { id: 4, text: "you have a message from Dosan" },
    ])

    const [show, setShow] = useState(false)

    const removeHandler = (id) => {
        const newArrey = notifications.filter(item => item.id !== id)
        setNotifications(newArrey)
    }

    return (
        <div onClick={(e) => {
            e.stopPropagation()
            setShow(true)
        }} >
            <span className="pointer">
                <NotificationsIcon color="error" className={scroll ? "text-dark" : 'text-white'}
                     />
                <span className="position-absolute translate-middle badge rounded-pill bg-danger">
                    {notifications.length}
                </span>
                <div className={show ? "" : "visually-hidden"}
                >
                    <Modal show={show} setShow={setShow}>
                        {notifications.length === 0 ? (<p className='p-3'>No Notification</p>) : (
                            <div className='bg-white rounded-3 z-3'>
                                {
                                    notifications.map((notif, index) => (
                                        <p key={notif.id} className='bg-light p-3 m-0 border-bottom pointer hover-bg-white flex-ac fs-14'>
                                            - {notif.text}
                                            <button className="btn btn-close btn-sm ms-auto text-dark mb-2"
                                                onClick={() => removeHandler(notif.id)}
                                            >x</button>
                                        </p>
                                    ))
                                }
                            </div>
                        )}
                    </Modal>
                </div>
            </span>
        </div>
    )
}

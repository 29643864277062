import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Accordion1() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className='mb-3'>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className='bg-primary text-white'
                >
                    <div className='flex-ac'>
                        <img src='img/bit.png' width={40} />
                        <p className='text-white ms-3 mb-0'>Bitcoin Wallet</p>
                    </div>

                </AccordionSummary>
                <AccordionDetails>
                    <div className=''>
                        <p>Recent Bitcoin Transactions</p>
                        <div className='bg-blue text-white rounded-3 mb-3'>
                            <div className='flex-ac p-2'>
                                <i className='bx bxs-receipt me-3 fs-3 rounded-circle border p-2 bg-primary'></i>
                                <div className='border-bottom pb-2 w-75'>
                                    <p className='mb-0 text-white'>Recieved Amount</p>
                                    <p className='mb-0 text-white fs-14'>+ 2.39498 BTC</p>
                                </div>
                            </div>
                            <div className='flex-ac p-2'>
                                <i className='bx bxs-credit-card me-3 fs-3 rounded-circle border p-2 bg-primary'></i>
                                <div className='border-bottom pb-2 w-75'>
                                    <p className='mb-0 text-white'>Sent Amount</p>
                                    <p className='mb-0 text-white fs-14'>+ 0.99498 BTC</p>
                                </div>
                            </div>
                            <div className='flex-ac p-2'>
                                <i className='bx bx-check me-3 fs-3 rounded-circle border p-2 bg-primary'></i>
                                <div className='border-bottom pb-2 w-75'>
                                    <p className='mb-0 text-white'>Total Amount</p>
                                    <p className='mb-0 text-white fs-14'>+ 4.89412 BTC</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-blue text-white rounded-3 text-center p-3 '>
                            <p className='text-white mb-2'>Bitcoin Wallet Address</p>
                            <div className='flex-ac bg-white rounded p-2 mb-3'>
                                <i className='bx bxl-bitcoin text-dark me-2 fs-5' ></i>
                                <p className='mb-0'>XAHS-AKDLAD4523AAI</p>
                            </div>
                            <img src='img/qr.png' />
                            <p className='text-white my-2 fs-14'>This is your Wallet ID</p>
                            <p className='mb-2 fs-12'>Scan This crediential to send or exchange crypto assets with just scaning this code</p>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>


            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className='mb-3'>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    className='bg-primary text-white'
                >
                    <div className='flex-ac'>
                        <img src='img/eth.png' width={40} />
                        <p className='text-white ms-3 mb-0'>Ethereum Wallet</p>
                    </div>

                </AccordionSummary>
                <AccordionDetails>
                    <div className=''>
                        <p>Recent Ethereum Transactions</p>
                        <div className='bg-blue text-white rounded-3 mb-3'>
                            <div className='flex-ac p-2'>
                                <i className='bx bxs-receipt me-3 fs-3 rounded-circle border p-2 bg-primary'></i>
                                <div className='border-bottom pb-2 w-75'>
                                    <p className='mb-0 text-white'>Recieved Amount</p>
                                    <p className='mb-0 text-white fs-14'>+ 1.39498 BTC</p>
                                </div>
                            </div>
                            <div className='flex-ac p-2'>
                                <i className='bx bxs-credit-card me-3 fs-3 rounded-circle border p-2 bg-primary'></i>
                                <div className='border-bottom pb-2 w-75'>
                                    <p className='mb-0 text-white'>Sent Amount</p>
                                    <p className='mb-0 text-white fs-14'>+ 2.99498 BTC</p>
                                </div>
                            </div>
                            <div className='flex-ac p-2'>
                                <i className='bx bx-check me-3 fs-3 rounded-circle border p-2 bg-primary'></i>
                                <div className='border-bottom pb-2 w-75'>
                                    <p className='mb-0 text-white'>Total Amount</p>
                                    <p className='mb-0 text-white fs-14'>+ 3.89412 BTC</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-blue text-white rounded-3 text-center p-3 '>
                            <p className='text-white mb-2'>Ethereum Wallet Address</p>
                            <div className='flex-ac bg-white rounded p-2 mb-3'>
                                <i className='bx bxl-bitcoin text-dark me-2 fs-5' ></i>
                                <p className='mb-0'>KAHS-AKDLAD4523AAI</p>
                            </div>
                            <img src='img/qr.png' />
                            <p className='text-white my-2 fs-14'>This is your Wallet ID</p>
                            <p className='mb-2 fs-12'>Scan This crediential to send or exchange crypto assets with just scaning this code</p>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>


            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    className='bg-primary text-white'
                >
                    <div className='flex-ac'>
                        <img src='img/rip.png' width={40} />
                        <p className='text-white ms-3 mb-0'>Litecoin Wallet</p>
                    </div>

                </AccordionSummary>
                <AccordionDetails>
                    <div className=''>
                        <p>Recent Litecoin Transactions</p>
                        <div className='bg-blue text-white rounded-3 mb-3'>
                            <div className='flex-ac p-2'>
                                <i className='bx bxs-receipt me-3 fs-3 rounded-circle border p-2 bg-primary'></i>
                                <div className='border-bottom pb-2 w-75'>
                                    <p className='mb-0 text-white'>Recieved Amount</p>
                                    <p className='mb-0 text-white fs-14'>+ 3.39498 BTC</p>
                                </div>
                            </div>
                            <div className='flex-ac p-2'>
                                <i className='bx bxs-credit-card me-3 fs-3 rounded-circle border p-2 bg-primary'></i>
                                <div className='border-bottom pb-2 w-75'>
                                    <p className='mb-0 text-white'>Sent Amount</p>
                                    <p className='mb-0 text-white fs-14'>+ 1.99498 BTC</p>
                                </div>
                            </div>
                            <div className='flex-ac p-2'>
                                <i className='bx bx-check me-3 fs-3 rounded-circle border p-2 bg-primary'></i>
                                <div className='border-bottom pb-2 w-75'>
                                    <p className='mb-0 text-white'>Total Amount</p>
                                    <p className='mb-0 text-white fs-14'>+ 5.89412 BTC</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-blue text-white rounded-3 text-center p-3 '>
                            <p className='text-white mb-2'>Litecoin Wallet Address</p>
                            <div className='flex-ac bg-white rounded p-2 mb-3'>
                                <i className='bx bxl-bitcoin text-dark me-2 fs-5' ></i>
                                <p className='mb-0'>XAHS-AKDLAD4523AAI</p>
                            </div>
                            <img src='img/qr.png' />
                            <p className='text-white my-2 fs-14'>This is your Wallet ID</p>
                            <p className='mb-2 fs-12'>Scan This crediential to send or exchange crypto assets with just scaning this code</p>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
import React, { useEffect, useRef, useContext } from 'react'
import SocialIcons from '../../Components/SocialIcons/SocialIcons'
import AreaChart from '../../Components/Charts/AreaChart'
import { userContext } from '../../Contexts/userContext'
import { supabase } from '../../supabase'
import { useNavigate } from 'react-router-dom'
import Input from "../../Validations/Input"
import {useForm} from "../../Components/Hooks/useForm"
import {reqValid , minValid , maxValid , emailValid} from "../../Validations/rouls"

export default function Profile() {

    const { userInfos, isLoading , isLogin } = useContext(userContext)

    const [formState , inputHandler] = useForm({
        name:{
            value:"",
            isValid: false
        },
        email:{
            value:"",
            isValid: false
        },
        password:{
            value:"",
            isValid: false
        },
    } , false)

    const navigate = useNavigate()

    const changeImage = async (e) => {

        let { data, error , } = await supabase.from("users")
            .update({ ...userInfos, img: e.target.files[0].name })
            .eq("id", userInfos.id)

        console.log(data, "error:", error);
    }

    useEffect(() => {
        if(!isLoading){
            !isLogin && navigate("/register")
        }
    } , [])

    const updateUser = async () => {

        const newUser = {
            ...userInfos,
            name:formState.inputs.name.value,
            email:formState.inputs.email.value,
            password:formState.inputs.password.value,
        }

        let {data , error} =await supabase.from("users")
                .update(newUser)
                .eq("id" , userInfos.id)

                console.log(data , "error:" ,error);
    }

    return (
        <>
        { isLoading ? ( <div className='position-absolute top-50 start-50'><div className='spinner-border'></div></div>
                    ) : (
            <div className='pb-5'  >
                
                        <>
                            <div className="bg-primary p-3">
                                <div className='flex-ac px-2 py-5'>
                                    <h6 className='text-white m-0 fw-bold'>Trader Profile</h6>
                                    <i className='bx bx-plus-medical text-white ms-auto fs-5 pointer'></i>
                                </div>
                            </div>
                            <div className=' flex-center' style={{ marginTop: "-60px" }}>
                                <span className='position-relative p-2 mb-3'>
                                    <div>
                                        <img src={`/img/${userInfos.img}`} className='rounded-circle border border-5' width={100} />
                                        <span className="badge  position-absolute bottom-0 end-0" style={{ width: 50 }}>
                                            <i className='bx bxs-camera text-dark rounded-circle border p-2 fs-6 bg-light pointer'></i>
                                            <input type='file' style={{ width: 35, marginLeft: "-34px", opacity: 0, zIndex: 2 }} onChange={changeImage} />
                                        </span>
                                    </div>
                                </span>
                            </div>
                            <div className='text-center'>
                                <h6 className='fw-bold'>{userInfos.name}</h6>
                                <p>London , England</p>
                            </div>
                            <div className='d-flex m-2 text-center bg-light mb-4'>
                                <div className='p-3 m-2 '>
                                    <img className='mb-2' src='img/t1.png' width={40} />
                                    <p className='mb-2'>${3890}</p>
                                    <p className='fs-14 flex-ac'>Transition</p>
                                </div>
                                <div className='p-3 m-2 '>
                                    <img className='mb-2' src='img/t1.png' width={40} />
                                    <p className='mb-2'>$71,504</p>
                                    <p className='fs-14 flex-ac'>Recieved</p>
                                </div>
                                <div className='p-3 m-2 '>
                                    <img className='mb-2' src='img/t1.png' width={40} />
                                    <p className='mb-2'>$14,369</p>
                                    <p className='fs-14 flex-ac'>Exchanged</p>
                                </div>
                            </div>
                            <div className='p-2 mb-4' >
                                <h6 className='fw-bold'>Account Value</h6>
                                <AreaChart />
                            </div>
                            <div className='p-2 mb-4'>
                                <h6 className='fw-bold'>Account Info</h6>
                                <div className='px-2 border bg-light my-3'>
                                    <div className='flex-ac border-bottom p-1'>
                                        <i className='bx bxs-user'></i>
                                        <Input 
                                            // placeholder={userInfos.name}
                                            value={userInfos.name}
                                            id={"name"}
                                            validations={[
                                                reqValid(),
                                                minValid(3),
                                                maxValid(30)
                                            ]}
                                            inputHandler={inputHandler}
                                            className={'form-control fs-14 w-75 border-0 bg-light'}
                                        />
                                        {/* <input type='text' className='form-control fs-14 w-75 border-0 bg-light' placeholder={userInfos.name} /> */}
                                    </div>
                                    <div className='flex-ac border-bottom p-1'>
                                        <i className='bx bxs-envelope' ></i>
                                        <Input 
                                            value={userInfos.email}
                                            id={"email"}
                                            validations={[
                                                reqValid(),
                                                minValid(3),
                                                maxValid(30)
                                            ]}
                                            inputHandler={inputHandler}
                                            className={'form-control fs-14 w-75 border-0 bg-light'}
                                        />
                                        {/* <input type='text' className='form-control fs-14 w-75 border-0 bg-light' placeholder={userInfos.email} /> */}
                                    </div>
                                    <div className='flex-ac border-bottom p-1'>
                                        <i className='bx bxs-lock-alt' ></i>
                                        <Input 
                                            value={"*********"}
                                            id={"password"}
                                            validations={[
                                                reqValid(),
                                                minValid(3),
                                                maxValid(30)
                                            ]}
                                            inputHandler={inputHandler}
                                            className={'form-control fs-14 w-75 border-0 bg-light'}
                                        />
                                        {/* <input type='text' className='form-control fs-14 w-75 border-0 bg-light' placeholder={"********"} /> */}
                                    </div>
                                    <div className='flex-ac border-bottom p-1'>
                                        <select className='form-select w-100 border-0 bg-light' >
                                            <option>Language</option>
                                            <option>English</option>
                                            <option>Spanish</option>
                                            <option>Turkish</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='p-2 mb-5'>
                                <h6 className='fw-bold'>Social Media</h6>
                                <div className='px-2 border bg-light my-3'>
                                    <div className='flex-ac border-bottom p-1'>
                                        <i className='bx bxl-facebook'></i>
                                        <input type='text' className='form-control fs-14 w-75 border-0 bg-light' placeholder={"Facebook url"} />
                                    </div>
                                    <div className='flex-ac border-bottom p-1'>
                                        <i className='bx bxl-twitter' ></i>
                                        <input type='text' className='form-control fs-14 w-75 border-0 bg-light' placeholder={"Twitter url"} />
                                    </div>
                                    <div className='flex-ac border-bottom p-1'>
                                        <i className='bx bxl-google' ></i>
                                        <input type='text' className='form-control fs-14 w-75 border-0 bg-light' placeholder={"Google url"} />
                                    </div>
                                </div>
                                <button className='btn btn-success w-100'
                                 onClick={updateUser}
                                >Update</button>
                            </div>
                            <SocialIcons />
                            <div className="flex-center my-4 p-3 fs-14 border-top">Copyright © All Right Reserved</div>
                        </>
                
            </div>)}
        </>
    )
}

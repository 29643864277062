import React ,{useContext} from 'react'
import Notification from '../../Components/Notification/Notification'
import Navbar from '../../Components/Header/Navbar'
import Accordion1 from '../../Components/Accordion/Accordin';
import SocialIcons from '../../Components/SocialIcons/SocialIcons';
import Transactions from '../../Components/Transactions/Transactions';
import { userContext } from '../../Contexts/userContext';


export default function Wallet() {

    const {bitInfos} = useContext(userContext)

    return (
        <div className='py-5'>
            <div className="bg-primary p-3">
                <div className='flex-ac px-2 pb-5'>
                    <h6 className='text-white m-0 fw-bold'>My Wallet</h6>
                    <i className='bx bx-plus-medical text-white ms-auto fs-5 pointer'></i>
                </div>
            </div>

            <div style={{ marginTop: "-40px" }}>
                <div className='d-flex  text-center'>
                    <div className='p-3 m-2 w-50 shadow rounded-3 bg-white'>
                        <img className='mb-2' src='img/bit.png' width={40} />
                        <p className='mb-2 fw-bold'>Bitcoin Wallet</p>
                        <p className='fs-14 flex-ac'><i className='bx bxs-up-arrow-alt text-success fs-5'></i>{bitInfos.amount} USD</p>
                    </div>
                    <div className='p-3 m-2 w-50 shadow rounded-3 bg-white'>
                        <img className='mb-2' src='img/eth.png' width={40} />
                        <p className='mb-2 fw-bold'>ETH Wallet</p>
                        <p className='fs-14 flex-ac'><i className='bx bxs-up-arrow-alt text-success fs-5'></i>{(bitInfos.amount / 90).toFixed(2)} USD</p>
                    </div>
                </div>
                <div className='d-flex mb-4 text-center'>
                    <div className='p-3 m-2 w-50 shadow rounded-3 bg-white'>
                        <img className='mb-2' src='img/rip.png' width={40} />
                        <p className='mb-2 fw-bold'>Ripple Wallet</p>
                        <p className='fs-14 flex-ac'><i className='bx bxs-down-arrow-alt text-danger fs-5'></i>{(bitInfos.amount / 75 ).toFixed(2)} USD</p>
                    </div>
                    <div className='p-3 m-2 w-50 shadow rounded-3 bg-white'>
                        <img className='mb-2' src='img/dash.png' width={40} />
                        <p className='mb-2 fw-bold'>Dash Wallet</p>
                        <p className='fs-14 flex-ac'><i className='bx bxs-up-arrow-alt text-success fs-5'></i>{(bitInfos.amount /150 ).toFixed(2)} USD</p>
                    </div>
                </div>
            </div>
            <div className='p-2 mb-3'>
                <h6 className='mb-3'>Cryptocoins Wallets</h6>
                <Accordion1 />
            </div>
            <div className='d-flex mb-4'>
                <div className='p-3 m-2 w-50 shadow rounded-3 bg-blue '>
                    <img className='mb-2' src='img/t1.png' width={40} />
                    <p className='mb-2 text-white'>Buy crypto</p>
                    <p className='fs-14 flex-ac text-white'>Lorem ipsum, dolor sit amet</p>
                </div>
                <div className='p-3 m-2 w-50 shadow rounded-3 bg-blue '>
                    <img className='mb-2' src='img/t1.png' width={40} />
                    <p className='mb-2 text-white'>Sell crypto</p>
                    <p className='fs-14 flex-ac text-white'>Lorem ipsum, dolor sit amet</p>
                </div>
            </div>
            <Transactions />
            <SocialIcons />
            <div className="flex-center my-5 p-3 fs-14">Copyright © All Right Reserved</div>
        </div>
    )
}

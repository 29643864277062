import React, { useReducer, useEffect, useState } from 'react'
import { validator } from './validator'

const reducer = (state, action) => {
    switch (action.type) {
        case "INPUT": {
            return {
                value: action.value,
                isValid: action.isValid
            }
        }
        default: {
            return state
        }
    }
}

export default function Input({
    id,
    inputHandler,
    validations,
    className,
    type,
    placeholder,
    value
}) {
    const [pass, setPass] = useState("")
    const [inputState, dispatch] = useReducer(reducer, {
        value: value,
        isValid: false
    })

    const changeHandler = (event) => {
        dispatch({
            type: "INPUT",
            value: event.target.value,
            isValid: validator(event.target.value, validations)
        })

    }

    useEffect(() => {

        inputHandler(id, inputState.value, inputState.isValid)

    }, [inputState, inputState.value])


    return (
        <div className='flex-ac'>
            <input
                type={type}
                onChange={changeHandler}
                value={inputState.value}
                className={`${className}`}
                style={{ outline: "none" }}
                placeholder={placeholder}
            />
            
            
        </div>
    )
}

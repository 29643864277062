import React from 'react'

export default function MemberBox({name , rate , count, img , num , coin}) {
  return (
    <div className='d-flex px-2 pt-2 pb-0 rounded-2 shadow mb-3'>
        <img src={`img/${img}`} width={40} height={40} className='rounded-circle'/>
        <div className='ms-3'>
            <p className='mb-0 '>{name}</p>
            <p className='fs-12'> Bonus <span className='text-success'>{count}</span> USD</p>
        </div>
        <div className='ms-auto'>
            <p className={'mb-0 fs-12 text-success'}>{rate} {coin}</p>
            <p className='mb-0 fs-12'>${num}</p>
        </div>
    </div>
  )
}

import React from 'react'

export default function TradeBox({name , rate , count, img , success}) {
  return (
    <div className='d-flex px-2 pt-2 pb-0 rounded-2'>
        <img src={`img/${img}`} width={50} height={50}/>
        <div className='ms-2'>
            <p className='mb-0 fw-bold'>{name}</p>
            <p className='fs-12'>{count}</p>
        </div>
        <div className='ms-auto'>
            <p className={success ? 'mb-0 fs-12 text-success' : 'mb-0 fs-12 text-danger'}>{rate}</p>
            <p className='mb-0 fs-12'>{success ? "increase " : "decrease "} 3.21%</p>
        </div>
    </div>
  )
}

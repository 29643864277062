import React from 'react'
import { BarChart1 } from '../../Components/Charts/BarChart1'
import AreaChart1 from '../../Components/Charts/AreaChart'
import MultiTypeChart from '../../Components/Charts/MultiTypeChart'
import ScatterChart1 from '../../Components/Charts/ScatterChart'
import PieChart1 from '../../Components/Charts/PieChart'
import RadarChart1 from '../../Components/Charts/RadarChart'

export default function Charts() {
    return (
        <div className='py-5 bg-light'>
            <div className='bg-primary text-white p-3 pb-5'>
                Charts
            </div>
            <div className='mx-2 bg-white py-2 mb-5' style={{marginTop:"-40px"}}>
                <BarChart1 />
            </div>
            <p className='fw-bold ps-2 mb-2'>chart2</p>
            <div className='mx-2 bg-white py-2 mb-5 mt-3' style={{marginTop:"-40px"}}>
                <AreaChart1 />
            </div>
            <p className='fw-bold ps-2 mb-2'>chart3</p>
            <div className='mx-2 bg-white py-2 mb-5 mt-3' style={{marginTop:"-40px"}}>
                <ScatterChart1 />
            </div>
            <p className='fw-bold ps-2 mb-2'>chart4</p>
            <div className='mx-2 bg-white py-2 mb-5 mt-3' style={{marginTop:"-40px"}}>
                <PieChart1 />
            </div>
            <p className='fw-bold ps-2 mb-2'>chart5</p>
            <div className='mx-2 bg-white py-2 mb-5 mt-3' style={{marginTop:"-40px"}}>
                <RadarChart1 />
            </div>
            <p className='fw-bold ps-2 mb-2'>chart6</p>
            <div className='mx-2 bg-white py-2 mb-5 mt-3' style={{marginTop:"-40px"}}>
                <MultiTypeChart />
            </div>
        </div>
    )
}

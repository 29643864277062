import React from 'react'
import {MapWithAddress} from "../../Components/Maps/Map"

export default function Contact() {
    

    return (
        <div className='py-5'>
            <div className="bg-primary p-3 mb-4">
                <div className='flex-ac px-2 pb-5'>
                    <h6 className='text-white m-0 fw-bold'>Contact</h6>
                    <i className='bx bx-plus-medical text-white ms-auto fs-5 pointer'></i>
                </div>
            </div>
            <div>
                {/* <MapWithAddress /> */}
            </div>
        </div>
    )
}

import React, { useState } from 'react'

export default function RadioBtn({ value: btnValue }) {

    const [value, setValue] = useState(btnValue)

    return (
        <div className={value ? 'shadow border bg-primary' : 'shadow border bg-light'}
            style={{ width: 60, height: 30, borderRadius: "1.5rem", position: "relative" }}
            onClick={() => setValue(prev => !prev)}
        >
            {value ? (
                <div className='rounded-circle position-absolute  border bg-light ' style={{ width: 30, height: 30, transition: "all .4s", left: "50%" }}></div>
            ) : (
                <div className='rounded-circle position-absolute  border bg-light' style={{ width: 30, height: 30, transition: "all .4s", left: 0 }}></div>
            )}

        </div>
    )
}

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./Sliders.css";

// import required modules
import { Pagination } from "swiper";
import Modal from "../Modals/Modal";



export default function UserSlider() {
    const [showModal, setShowModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [name, setName] = useState("")
    const [sendName, setSendName] = useState("")
    const [reciverName, setReciverName] = useState("")
    const [data, setData] = useState(
        [
            { id: 1, name: "M.Bahamin",img:"u1.png" },
            { id: 2, name: "A.Vayer",img:"u2.png" },
            { id: 3, name: "L.Messi",img:"u3.png" },
            { id: 4, name: "K.Boskas",img:"u4.png" },
        ]
    )

    const addUser = () => {
        setData(prev => {
            return [...data, { id: data.length + 1, name }]
        })
        setShowModal(false)
    }

    return (
        <>
            <Modal show={showModal} setShow={setShowModal} >
                <div className='px-2 py-3 mb-4'>
                    <div className="flex-ac">
                        <h6 className='fw-bold'>Add New Reciever</h6>
                        <button className="btn btn-close ms-auto"
                            onClick={() => setShowModal(false)}
                        ></button>
                    </div>
                    <div className='px-2 border bg-light my-3'>
                        <div className='flex-ac border-bottom p-1'>
                            <i className='bx bxs-user'></i>
                            <input type='text' className='form-control fs-14 w-75 border-0 bg-light'
                                value={name} onChange={e => setName(e.target.value)} placeholder="Reciever Name" />
                        </div>
                        <div className='flex-ac border-bottom p-1'>
                            <i className='bx bx-briefcase' ></i>
                            <input type='text' className='form-control fs-14 w-75 border-0 bg-light' placeholder={"Wallet Address"} />
                        </div>
                        <div className='flex-ac border-bottom p-1'>
                            <select className='form-select w-100 border-0 bg-light' >
                                <option>Wallet Currency</option>
                                <option>Bitcoin</option>
                                <option>Etherium</option>
                                <option>Dashcoin</option>
                            </select>
                        </div>
                        <div className='flex-ac border-bottom p-1'>
                            <i className='bx bx-message-square-detail' ></i>
                            <input type='text' className='form-control fs-14 w-75 border-0 bg-light' placeholder={"Message"} />
                        </div>
                    </div>
                    <button className="btn btn-success" onClick={addUser}>Add New</button>
                </div>
            </Modal>
            <Modal show={showUserModal} setShow={setShowUserModal} >
                <div className='px-2 py-3 mb-4'>
                    <div className="flex-ac">
                        <h6 className='fw-bold'>Send</h6>
                        <button className="btn btn-close ms-auto"
                            onClick={() => setShowModal(false)}
                        ></button>
                    </div>
                    <div className='px-2 border bg-light my-3'>
                        <div className='flex-ac border-bottom p-1'>
                            <i className='bx bxs-user'></i>
                            <input type='text' className='form-control fs-14 w-75 border-0 bg-light'
                                value={reciverName} onChange={e => setReciverName(e.target.value)} />
                        </div>
                        <div className='flex-ac border-bottom p-1'>
                            <i className='bx bx-briefcase' ></i>
                            <input type='text' className='form-control fs-14 w-75 border-0 bg-light' placeholder={"Wallet Address"} />
                        </div>
                        <div className='flex-ac border-bottom p-1'>
                            <select className='form-select w-100 border-0 bg-light' >
                                <option>Wallet Currency</option>
                                <option>Bitcoin</option>
                                <option>Etherium</option>
                                <option>Dashcoin</option>
                            </select>
                        </div>
                        <div className='flex-ac border-bottom p-1'>
                            <i className='bx bx-message-square-detail' ></i>
                            <input type='text' className='form-control fs-14 w-75 border-0 bg-light' placeholder={"Message"} />
                        </div>
                    </div>
                    <button className="btn btn-success" >Send</button>
                </div>
            </Modal>


            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                // modules={[Pagination]}
                className="user-swiper"
            >
                <SwiperSlide >
                    <div onClick={e => { 
                        setShowModal(true) 
                        setName('')
                        }}>
                        <i className='bx bx-plus-medical text-success ms-auto fs-5 pointer my-3 border p-2 rounded-circle'></i>
                        <p >Add new</p>
                    </div>
                </SwiperSlide>

                {
                    data.map(user => (
                        <SwiperSlide key={user.id}>
                            <div className="pointer" onClick={()=> {
                                setShowUserModal(true)
                                setReciverName(user.name)
                            }}>
                                <img src={`/img/${user.img}`} className="border" />
                                <p>{user.name}</p>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </>
    );
}

import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { supabase } from '../../supabase';
import Input from '../../Validations/Input';
import { useForm } from '../../Components/Hooks/useForm';
import { reqValid, maxValid, minValid, emailValid } from '../../Validations/rouls';
import { userContext } from '../../Contexts/userContext';


export default function Login() {

    const [formState , inputHandler] = useForm({
        email : {
            value:"",
            isValid:false
        },
        pass : {
            value:"",
            isValid:false
        },
    } , false)

    const navigate = useNavigate()

    const {setIsLogin , setUserInfos} = React.useContext(userContext)

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(formState.isFormValid){
            let {data , error} = await supabase.from("users").select("*").eq("email" , formState.inputs.email.value)
            console.log(data ,"error:" , error);
            if(error === null){
                setIsLogin(true)
                setUserInfos(data[0])
                localStorage.setItem("token" , data[0].id)
                navigate("/")
            } else {
                alert("your email and passwor is incorrrect")
            }
        } else {
            alert("your form is invalid")
        }
       
    };

    return (
        <>
            <Box
                sx={{
                    marginTop: 12,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}

            >
                <Avatar sx={{ m: 1, bgcolor: 'success.light' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                    Log in
                </Typography>
                <Container >
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            sx={{ fontSize: 12, mb: 2 }}
                        ><i className='bx bxl-facebook fs-6' ></i>
                            Login with FACEBOOK</Button>
                        <Button
                            fullWidth
                            variant='contained'
                            color='error'
                            sx={{ fontSize: 12, mb: 3 }}
                        >
                            <i className='bx bxl-google fs-6' ></i>
                            Login with GOOGLE</Button>
                        <Grid item xs={12} sx={{ display: "flex" }} className='border flex-ac p-1 px-2'>
                            <i className='mb-1 fs-6 bx bx-envelope me-3' ></i>
                            <Input
                                id="email"
                                inputHandler={inputHandler}
                                validations={[
                                    reqValid(),
                                    minValid(3),
                                    maxValid(18),
                                    emailValid()
                                ]}
                                type="text"
                                className="border-0"
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex" }} className='border flex-ac p-1 px-2'>
                            <i className='mb-1 fs-6 bx bx-lock-alt me-3' ></i>
                            <Input
                                id="pass"
                                inputHandler={inputHandler}
                                validations={[
                                    reqValid(),
                                    minValid(3),
                                    maxValid(18),
                                ]}
                                type="text"
                                className="border-0"
                            />
                        </Grid>
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: "success.light" }}
                            color='success'
                        >
                            Log In
                        </Button>
                        <Grid item xs >
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <p className='fs-14'> Don't have an account?
                                <Link to="/register" className='fw-bold'>
                                    Sign Up
                                </Link>
                            </p>
                        </Grid>
                    </Box>
                </Container>
            </Box>

        </>
    );
}
import TransactionBox from "./TransactionBox";

export default function Transactions() {
    return (
        <div className="bg-white p-3 z-3 rounded-top pointer mb-4" style={{ marginTop: "-20px" }}>
            <h6 className="fw-bold my-3">Recent Transactions</h6>
            <div>
                <TransactionBox name="Bitcoin"  date="08-24 20.04PM"  rate="+0.94853" success={true}  count="$2,748.94" img="bit.png"/>
                <TransactionBox name="Ethereum"  date="08-21 17.20PM"  rate="+1.4253" success={true}  count="$1,241.10" img="eth.png"/>
                <TransactionBox name="Dashcoin"  date="08-20 20.25PM"  rate="+0.2536" success={true}  count="$3,721.48" img="dash.png"/>
                <TransactionBox name="Ripple"  date="08-19 14.10PM"  rate="-0.7423" success={false}  count="$0,989.85" img="rip.png"/>
            </div>
        </div>
    )
}

import React from 'react'

export default function TransactionBox({name , date , rate , count, img , success}) {
  return (
    <div className='mb-2 d-flex shadow px-2 pt-2 pb-0 rounded-2'>
        <img src={`img/${img}`} width={40} height={40}/>
        <div className='ms-2'>
            <p className='mb-0 fs-14'>{name}</p>
            <p className='fs-12'>{date}</p>
        </div>
        <div className='ms-auto'>
            <p className={success ? 'mb-0 fs-12 text-success' : 'mb-0 fs-12 text-danger'}>{rate}</p>
            <p className='mb-0 fs-12'>{count}</p>
        </div>
    </div>
  )
}

import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useState , useEffect} from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CabinIcon from '@mui/icons-material/Cabin';
import Person2Icon from '@mui/icons-material/Person2';
import WalletIcon from '@mui/icons-material/Wallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import { Link, useNavigate } from 'react-router-dom';

export default function Footer() {

    const [value, setValue] = useState(0)
    const [display, setDisplay] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        window.addEventListener("resize" , (event) => {
            const screen1 = (event.target.innerWidth);
            
            if(Number(screen1) < 500){
                setDisplay(true)
            } else {
                setDisplay(false)
            }
        })
    } , [])
   

    return (
        <>
        {display  ? (
        <Paper sx={{ position: "fixed", top:"90%", left: 0, right: 0, overflow: "hidden", width: "100%" , zIndex:10,padding:"7px 0" , paddingBottom:"10px"}} elevation={5}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);

                }}
            >
                <BottomNavigationAction label="Home"
                    sx={{ width: "25%" , minWidth:60 }}
                    icon={<CabinIcon className='text-primary fs-1'/>} onClick={() => navigate("/")} />
                <BottomNavigationAction label="Wallet"
                    sx={{ width: "25%" , minWidth:60 }}
                    icon={<WalletIcon className='text-primary fs-1'/>} onClick={() => navigate("/wallet")} />
                <BottomNavigationAction label=""
                    sx={{ width: "25%" , minWidth:60 }}
                    icon={<AddCircleIcon className='text-primary fs-1'/>} onClick={() => navigate("/login")} />
                <BottomNavigationAction label="Cards"
                    sx={{ width: "25%" , minWidth:60 }}
                    icon={<CreditCardIcon className='text-primary fs-1'/>} onClick={() => navigate("/cards")} />
                <BottomNavigationAction label="Profile"
                    sx={{ width: "25%" , minWidth:60 }}
                    icon={<Person2Icon className='text-primary fs-1'/>} onClick={() => navigate("/profile")} />
            </BottomNavigation>
        </Paper>) : (
            <Paper sx={{ position: "absolute", bottom: 0, left: 0, right: 0, overflow: "hidden", width: "100%" , zIndex:10,padding:"7px 0"}} elevation={5}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);

                }}
            >
                <BottomNavigationAction label="Home"
                    sx={{ width: "25%" , minWidth:60 }}
                    icon={<CabinIcon className='text-primary fs-1'/>} onClick={() => navigate("/")} />
                <BottomNavigationAction label="Wallet"
                    sx={{ width: "25%" , minWidth:60 }}
                    icon={<WalletIcon className='text-primary fs-1'/>} onClick={() => navigate("/wallet")} />
                <BottomNavigationAction label=""
                    sx={{ width: "25%" , minWidth:60 }}
                    icon={<AddCircleIcon className='text-primary fs-1'/>} onClick={() => navigate("/login")} />
                <BottomNavigationAction label="Cards"
                    sx={{ width: "25%" , minWidth:60 }}
                    icon={<CreditCardIcon className='text-primary fs-1'/>} onClick={() => navigate("/cards")} />
                <BottomNavigationAction label="Profile"
                    sx={{ width: "25%" , minWidth:60 }}
                    icon={<Person2Icon className='text-primary fs-1'/>} onClick={() => navigate("/profile")} />
            </BottomNavigation>
        </Paper>
        )}
    </>)
}

import { ToggleButton } from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import "./Header.css"
import { Link } from 'react-router-dom';
import { userContext } from '../../Contexts/userContext';
import Modal from '../Modals/Modal';

export default function Navbar({ scroll }) {

  const [state, setState] = React.useState(false);

  const { logoutUser } = React.useContext(userContext)

  return (
    <div className='ms-auto text-white '>
      <ToggleButton value="justify" aria-label="justified" onClick={() => setState(prev => !prev)} className='border-0'>
        {
          state ? <i className={scroll ? `bx bx-x  fs-2` : `bx bx-x text-white fs-2`}></i> : <MenuIcon  className={scroll ? 'border-0 ' : "border-0 text-white"} />
        }
      </ToggleButton>
      <div className={state ? " position-absolute top-0 start-0" : "position-absolute top-0 nav-left-400"} style={{width:"100%" , height:600 , backgroundColor:"rgba(10,10,10,.4)"}}
        onClick={()=> setState(false)}
      >
        <div className={state ? 'nav-toggle-main start-0' : 'nav-toggle-main'} >
          <ul className='m-0 p-0 bg-white' style={{ overflowX: "hidden", height: "80vh" }}>
            <Link to="/">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer mt-2'>
                <i className='bx bxs-dashboard text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>Dashboard</p>
              </li>
            </Link>
            <Link to="/wallet">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer'>
                <i className='bx bxs-wallet text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>My Wallet</p>
              </li>
            </Link>
            <Link to="/trade">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer'>
                <i className='bx bx-line-chart text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>Crypto Trade</p>
              </li>
            </Link>
            <Link to="/buy-sell">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer'>
                <i className='bx bx-cart-add text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>Buy & Sell</p>
              </li>
            </Link>
            <Link to="/profile">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer'>
                <i className='bx bxs-user-badge text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>Trader Profile</p>
              </li>
            </Link>
            <Link to="/profile">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer'>
                <i className='bx bxs-cog text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>Settings</p>
              </li>
            </Link>
            <Link to="/affiliate">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer'>
                <i className='bx bx-desktop text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>Affiliate</p>
              </li>
            </Link>
            <Link to="/login">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer'>
                <i className='bx bx-log-in-circle text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>Login/Register</p>
              </li>
            </Link>
            <Link to="/wizard">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer'>
                <i className='bx bx-window-alt text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>Wizards</p>
              </li>
            </Link>
            <Link to="/charts">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer'>
                <i className='bx bx-chart text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>Charts</p>
              </li>
            </Link>
            <Link to="/">
              <li className='flex-ac w-100 p-2 hover-bg-light pointer' onClick={logoutUser}>
                <i className='bx bx-log-out-circle text-blue me-2 fs-2'></i>
                <p className='m-0 fs-14'>Log out</p>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import "./Wizard.css"
import { Link, useNavigate } from 'react-router-dom';

export default function Wizard() {

    const [data, setData] = useState(
        [
            { id: 1, name: "Let's get started", img: "w1.png" },
            { id: 2, name: "Easy Crypto Trading", img: "w2.png" },
            { id: 3, name: "Set Up Account Now", img: "w3.png" },
        ]
    )

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span className="' + className + '">' + (index + 1) + "</span>";
        },
    };

    const navigate = useNavigate()

    return (
        <div className='py-5 bg-light h-100'>
            <div>
                <>

                    <Swiper
                        pagination={pagination}

                        modules={[Pagination]}
                        className="wizard-swiper"
                    >
                        {
                            data.map(user => (
                                <SwiperSlide key={user.id}>
                                    <div className='position-relative'>
                                        <img src={`/img/${user.img}`}  />
                                        <p className='fw-bold'>{user.name}</p>
                                        <p className='px-3'>
                                            Never a better time than now to start thinking
                                            about how you can manage all your finances and crypto assets from one place.
                                        </p>
                                        <button className='btn btn-primary rounded-3'
                                             onClick={() => navigate("/register")}
                                        >Create Account</button>
                                        <span className='position-absolute pointer' style={{bottom:"-50px" , right:"14px"}}
                                            onClick={() => navigate("/")}
                                        >skip</span>
                                    </div>
                                    
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </>
            </div>
        </div>
    )
}

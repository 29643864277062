import React from 'react'
import RadioBtn from '../RadioBtn/RadioBtn'

export default function SettingItem({value , name ,img ,title}) {
    return (
        <div className='flex-ac border p-3'>
            <img src={`/img/${img}`} width={40} />
            <h6 className='fw-bold m-0 mx-2 '>{title}</h6>
            <p className='m-0 fs-14'>({name})</p>
            <div className='ms-auto'>
                <RadioBtn value={value}/>
            </div>
        </div>
    )
}

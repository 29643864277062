import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../../supabase';
import Input from '../../Validations/Input';
import { useForm } from '../../Components/Hooks/useForm';
import { reqValid, maxValid, minValid, emailValid } from '../../Validations/rouls';
import { userContext } from '../../Contexts/userContext';

export default function Register() {

  const [formState, inputHandler] = useForm({
    name: {
      value: "",
      isValid: false
    },
    email: {
      value: "",
      isValid: false
    },
    password: {
      value: "",
      isValid: false
    },
  }, false)

  const navigate = useNavigate()

  const {setIsLogin , setUserInfos} = React.useContext(userContext)

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formState.isFormValid) {
      let newUser = {
        name:formState.inputs.name.value,
        email:formState.inputs.email.value,
        password:formState.inputs.password.value,
      }

      let { data, error } = await supabase.from("users").insert(newUser)
      console.log(data, "error", error);
      if(error === null){
        let { data, error } = await supabase.from("users").select("*").eq("email" , formState.inputs.email.value)
            if(error===null){
              setIsLogin(true)
              setUserInfos(data[0])
              localStorage.setItem("token" , data[0].id)
              navigate("/")
            }
      }
    } else {
      alert("your form is invalid")
    }


  };

  console.log(formState);

  return (

    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 12,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'success.light' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: "90%", mb: 2 }}>

          <Button
            fullWidth
            variant='contained'
            color='primary'
            sx={{ fontSize: 12, mb: 2 }}
          ><i className='bx bxl-facebook fs-6' ></i>
            Login with FACEBOOK</Button>
          <Button
            fullWidth
            variant='contained'
            color='error'
            sx={{ fontSize: 12, mb: 3 }}
          >
            <i className='bx bxl-google fs-6' ></i>
            Login with GOOGLE</Button>


          <Grid item xs={12} sm={12} sx={{ display: "flex" }} className='border flex-ac p-1 px-2 mb-2'>
            <i className='mb-1 fs-6 bx bx-user me-3' ></i>
            <Input
              id="name"
              inputHandler={inputHandler}
              validations={[
                reqValid(),
                minValid(3),
                maxValid(18)
              ]}
              className="border-0"
              type="text"
              placeholder=""
            />
            {formState.inputs.name.isValid ? (
              <i className='bx bx-check text-success'></i>) : (
              <></>
            )}
          </Grid>

          <Grid item xs={12} sx={{ display: "flex" }} className='border flex-ac p-1 px-2 mb-2'>
            <i className='mb-1 fs-6 bx bx-envelope me-3' ></i>
            <Input
              id="email"
              inputHandler={inputHandler}
              validations={[
                reqValid(),
                minValid(6),
                maxValid(18),
                emailValid()
              ]}
              type="text"
              className="border-0"
            />
            {formState.inputs.email.isValid ? (
              <i className='bx bx-check text-success'></i>) : (
              <></>
            )}
          </Grid>
          <div style={{ width: 260, overflow: "hidden" }}>
            {formState.inputs.password.value &&
            <div style={{ height: 5, width: (formState.inputs.password.value?.length) * 25 }}
              className={(
                formState.inputs.password.value.length > 0 && formState.inputs.password.value.length < 6 ?
                  'bg-danger' : formState.inputs.password.value.length > 5 && formState.inputs.password.value.length < 9 ?
                    'bg-warning' : formState.inputs.password.value.length > 8 ? 'bg-success' : ''

              )}
            ></div>}
          </div>
          <Grid item xs={12} sx={{ display: "flex", mb: 3 }} className='border flex-ac p-1 px-2'>
            <i className='mb-1 fs-6 bx bx-lock-alt me-3' ></i>
            <Input
              id="password"
              inputHandler={inputHandler}
              validations={[
                reqValid(),
                minValid(5),
                maxValid(28)
              ]}
              type="text"
              className="border-0"
            />
          </Grid>

          <Grid item xs={12} >
            <FormControlLabel
              sx={{ fontSize: 12 }}
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive inspiration and updates via email."
            />
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, bgcolor: "success.light" }}
            color='success'
          >
            Sign Up
          </Button>

          <Grid item sx={{ textAlign: "center", mb: 10 }}>
            <p className='fs-14'> Already have an account?
              <Link to="/login" className='fw-bold'>
                {" "} Sign in
              </Link>
            </p>
          </Grid>

        </Box>
      </Box>

    </Container>

  );
}
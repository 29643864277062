import React from 'react'
import Navbar from '../../Components/Header/Navbar'
import Notification from '../../Components/Notification/Notification'
import { BarChart1 } from '../../Components/Charts/BarChart1'
import SocialIcons from '../../Components/SocialIcons/SocialIcons'
import MemberBox from '../../Components/MemberBox/MemberBox'

export default function Affiliate() {
    return (
        <div className='py-5 position-relative'>
            <div className="bg-primary p-3">
                <div className='flex-ac px-2 pb-5'>
                    <h6 className='text-white m-0 fw-bold'>Crypto Trade</h6>
                    <i className='bx bx-plus-medical text-white ms-auto fs-5 pointer'></i>
                </div>
            </div>

            <div style={{ marginTop: "-40px" }} className=' mx-2 mb-5'>
                <div className='d-flex rounded-3 ps-3 mb-3' style={{ backgroundImage: "url('/img/ref1.png')", backgroundSize: "cover" }}>
                    <div>
                        <p className='text-white mt-2 fs-5 mb-2'>$1.249.03</p>
                        <p className='text-white mb-2'>Total Referal Earnings</p>
                        <p className='text-white border rounded-3 fs-12 p-1'>This month 14.55 <i className='bx bx-up-arrow-alt text-success fs-6' ></i></p>
                    </div>
                </div>
                <div className='d-flex rounded-3 ps-3 mb-3' style={{ backgroundImage: "url('/img/ref2.png')", backgroundSize: "cover" }}>
                    <div>
                        <p className='text-white mt-2 fs-5 mb-2'>$2.752.74</p>
                        <p className='text-white mb-2'>Pending Earnings</p>
                        <p className='text-white border rounded-3 fs-12 p-1'>This month 11.25 <i className='bx bx-up-arrow-alt text-success fs-6' ></i></p>
                    </div>
                </div>
                <div className='d-flex rounded-3 ps-3 mb-3' style={{ backgroundImage: "url('/img/ref3.png')", backgroundSize: "cover" }}>
                    <div>
                        <p className='text-white mt-2 fs-5 mb-2'>$3.721.41</p>
                        <p className='text-white mb-2'>Ready to Payout</p>
                        <p className='text-white border rounded-3 fs-12 p-1'>This month 17.10 <i className='bx bx-up-arrow-alt text-success fs-6' ></i></p>
                    </div>
                </div>
            </div>
            <div>
                <BarChart1 />
            </div>
            <div className='m-2 border mb-4'>
                <div className='d-flex bg-primary text-white mb-3'>
                    <div className='m-2'>
                        <p className='text-white mb-1 fw-bold'>Refer Fiends $ Earn</p>
                        <p className='text-white fs-14'>Lorem ipisum dolor sit amet</p>
                    </div>
                    <span className='bg-warning ms-5 mb-3 px-2'>$20 <br /> Bouns</span>
                </div>
                <h6 className='m-2 my-4 fw-bold'>Your Referal Link</h6>
                <div className='m-3 border d-flex '>
                    <input type='text' className='border-0' />
                    <button className='bg-primary btn ms-auto'>
                        <i className='bx bx-copy-alt text-white '></i>
                    </button>
                </div>
                <h6 className='m-2 my-4 fw-bold'>Share Referal Link</h6>
                <div className='p-2'>
                    <button className='mb-3 flex-ac justify-content-center w-100 btn btn-success'>
                        <i className='fs-5 me-3 text-white bx bxl-facebook'></i>
                        Facebook</button>
                    <button className='mb-3 flex-ac justify-content-center w-100 btn btn-primary'>
                        <i className='fs-5 me-3 text-white bx bxl-twitter'></i>
                        Twitter</button>
                    <button className='mb-3 flex-ac justify-content-center w-100 btn btn-danger'>
                        <i className='fs-5 me-3 text-white bx bxl-google'></i>
                        Google Plus</button>
                </div>
            </div>
            <div className='d-flex mb-1 text-center'>
                <div className='p-3 m-2 w-50 shadow rounded-3'>
                    <img className='mb-2' src='img/i1.png' width={60} />
                    <p className='mb-2 fw-bold'>524 Click</p>
                    <p className='fs-14 flex-ac'>Referral Clicks</p>
                </div>
                <div className='p-3 m-2 w-50 shadow rounded-3'>
                    <img className='mb-2' src='img/i2.png' width={60} />
                    <p className='mb-2 fw-bold'>142 Signup</p>
                    <p className='fs-14 flex-ac'>Successful Signup</p>
                </div>
            </div>
            <div className='d-flex mb-4 text-center'>
                <div className='p-3 m-2 w-50 shadow rounded-3'>
                    <img className='mb-2' src='img/i3.png' width={60} />
                    <p className='mb-2 fw-bold'>$ 1653.24</p>
                    <p className='fs-14 flex-ac'>Creadit Earned</p>
                </div>
                <div className='p-3 m-2 w-50 shadow rounded-3'>
                    <img className='mb-2' src='img/i4.png' width={60} />
                    <p className='mb-2 fw-bold'>0.86543 BTC</p>
                    <p className='fs-14 flex-ac'>Crypto Exchanged</p>
                </div>
            </div>
            <h6 className='fw-bold mt-4 mb-2 ps-2'>Recent Refeard Members</h6>
            <div className='p-2 mb-4'>
                <MemberBox name="Member ID 25ul" rate="-0.7423"  coin="BTC" num="25.125"  count="$0,989.85" img="user-1.png" />
                <MemberBox name="Member ID 32r1" rate="+0.2536"  coin="ETH" num="75.485" count="$3,721.48" img="user-2.png" />
                <MemberBox name="Member ID 15x5" rate="-1.4253"  coin="XRP" num="215.12"  count="$1,241.10" img="user-3.png" />
                <MemberBox name="Member ID hj14" rate="+0.94853"  coin="LTC" num="85.45" count="$2,748.94" img="user-4.png" />
            </div>
            <SocialIcons />
            <div className="flex-center my-5 p-3 fs-14">Copyright © All Right Reserved</div>
        </div>
    )
}

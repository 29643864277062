import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const data = [
  {
    name: "A",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "B",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "C",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "D",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "E",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "F",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "G",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "H",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "I",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "J",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "K",
    uv: 1390,
    pv: 2800,
    amt: 2500
  },
];

export default function MultiTypeChart({color1= "#82ca9d", color2="#8884d8"}) {
  return (
    <LineChart
      width={300}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 10,
        left: 10,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="pv"
        stroke="#8884d8"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="uv" stroke={color1} />
    </LineChart>
  );
}

import { useReducer } from "react"

const reducer = (state, action) => {
    switch (action.type) {
        case "INPUTS": {
            let isFormValid = true
            for (const key in state.inputs) {
                if (key === action.id) {
                    isFormValid = isFormValid && action.isValid
                } else {
                    isFormValid = isFormValid && state.inputs[key].isValid
                }
            }
            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    [action.id]: {
                        value: action.value,
                        isValid: action.isValid
                    }
                },
                isFormValid: isFormValid
            }
        }
        default: {
            return state
        }
    }
}

export const useForm = (inputs, validationsInputs) => {
    const [formState, dispatch] = useReducer(reducer, {
        inputs,
        isFormValid: validationsInputs
    })

    const inputHandler = (id, value, isValid) => {
        dispatch({
            type: "INPUTS",
            id, value, isValid
        })
    }

    return [formState, inputHandler]
}